import React from 'react'
import Layout from '../../components/layout/layout'

export default () => (
  <Layout>
    <main>
      <section>
        <div className="page contact thanks">
          <h1>Bedankt!</h1>
          <p>We nemen zo snel mogelijk contact met je op.</p>
        </div>
      </section>
    </main>
  </Layout>
)
